<template>
	<div class="tables">
		<div class="toolbar" ref="toolbar" v-if="showToolbar">
			<div class="info-box" v-if="showInfo">
				<slot name="info-item"></slot>
			</div>
			<div class="search-box" v-if="showSearch">
				<el-form ref="searchForm" :model="searchFormData" :inline="true" :show-message="false"
					@submit.native.prevent>
					<!--自定义搜索内容 -->
					<slot name="search-item"></slot>
					<!--搜索-->
					<el-form-item>
						<el-button type="primary" @click="onSearch" size="small" icon="el-icon-search" plain>查询
						</el-button>
					</el-form-item>
					<!--重置-->
					<el-form-item>
						<el-button @click="resetForm" size="small" icon="el-icon-refresh" plain>重置
						</el-button>
					</el-form-item>
				</el-form>
			</div>
			<div class="action-box">
				<!--自定义操作按钮-->
				<slot name="action-item"></slot>

				<el-button v-if="showAdd" @click="addItem" size="small" icon="el-icon-plus" plain>
					{{ addName }}
				</el-button>
				<el-button v-if="showDelete" @click="deleteItem" size="small" icon="el-icon-delete" plain>删除
				</el-button>
				<el-button v-if="showSave" @click="saveTable" size="small" icon="el-icon-check" plain>保存
				</el-button>
				<el-button v-if="showPrint" @click="printTable" size="small" icon="el-icon-printer" plain>打印
				</el-button>
				<el-button v-if="showOutput" @click="exportTable" size="small" icon="el-icon-document" plain>导出
				</el-button>
				<el-button v-if="showRefresh" @click="refreshTable" size="small" icon="el-icon-refresh-right" plain>刷新
				</el-button>
				<!--自定义操作按钮-->
				<slot name="action-item-end"></slot>
			</div>
		</div>
		<slot name="count-info"></slot>
		<div class="table" :style="tableStyle">
			<vxe-table ref="xTable" :loading="isLoading" :data="tableData" :edit-rules="validRules"
				:tree-config="treeConfig"
				:edit-config="{ trigger: 'click', mode: 'cell', showIcon: false, showStatus: true }" height="100%"
				:border="isBorder" resizable highlight-hover-row :sort-config="{ sortMethod: customSortMethod }">
				<!--多选-->
				<vxe-table-column v-if="showDelete" type="checkbox" width="60"></vxe-table-column>
				<!--序号-->
				<vxe-table-column v-if="showSeq" title="#" type="seq" width="60" align="center" />
				<!--自定义表格内容-->
				<slot name="table-item"></slot>
			</vxe-table>
		</div>
		<div class="pagination" ref="pagination">

			<el-pagination v-if="totalPage !== -1" @size-change="handleSizeChange" @current-change="handleCurrentChange"
				:current-page="currentPage" :page-sizes="pageSizeOpts" :page-size="pageSize"
				layout="total, sizes, prev, pager, next, jumper" :total="totalPage" :hide-on-single-page="true">
			</el-pagination>
		</div>
		<div class="remark" ref="remark">
			<el-input v-if="showRemark" type="textarea" :rows="2" placeholder="请填写备注信息" v-model="remarkContent" />
		</div>
	</div>
</template>

<script>
export default {
	name: 'tables',
	props: {
		isBorder: {
			type: Boolean,
			default: true
		},
		treeConfig: {
			type: Object,
			default() {
				return {
					children: 'child',
					expandAll: true
				}
			}
		},
		totalPage: {
			type: Number,
			default() {
				return 0
			}
		},
		tableName: {
			type: String,
			default() {
				return ''
			}
		},
		tableData: {
			type: Array,
			default() {
				return []
			}
		},
		validRules: {
			type: Object,
			default() {
				return {}
			}
		},
		searchFormData: {
			type: Object,
			default() {
				return {}
			}
		},
		showInfo: {
			type: Boolean,
			default: false
		},
		showToolbar: {
			type: Boolean,
			default: true
		},
		showSeq: {
			type: Boolean,
			default: true
		},
		showSearch: {
			type: Boolean,
			default: false
		},
		handleSearch: {
			type: Boolean,
			default: false
		},
		showAdd: {
			type: Boolean,
			default: false
		},
		addName: {
			type: String,
			default() {
				return '添加'
			}
		},
		addType: {
			type: String,
			default() {
				return 'inTable'
			}
		},
		showDelete: {
			type: Boolean,
			default: false
		},
		showSave: {
			type: Boolean,
			default: false
		},
		showPrint: {
			type: Boolean,
			default: false
		},
		showOutput: {
			type: Boolean,
			default: false
		},
		showRefresh: {
			type: Boolean,
			default: false
		},
		showRemark: {
			type: Boolean,
			default: false
		},
		newRecord: {
			type: Object,
			default() {
				return {}
			}
		},
		remarkContent: {
			type: String,
			default() {
				return ''
			}
		},
		isLoading: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			tableStyle: '',
			currentPage: 1,
			pageSize: 20,
			pageSizeOpts: [20, 50, 100, 500, 1000]
		}
	},
	mounted() {
		// this.tableStyle = `height: calc( 100% - ${this.$refs.toolbar.offsetHeight}px );`
		this.tableStyle = `height: calc( 100% - ${this.$refs.toolbar.offsetHeight + this.$refs.pagination.offsetHeight + this.$refs.remark.offsetHeight}px );`
		// 第一次加载数据
		this.getTableList()
	},
	methods: {
		// 刷新数据
		getTableList() {
			this.$emit('getList', this.currentPage, this.pageSize)
		},
		// 改变页码
		handleSizeChange(pageSize) {
			this.pageSize = pageSize
			this.currentPage = 1
			this.getTableList()
		},
		// 改变页数
		handleCurrentChange(currentPage) {
			this.currentPage = currentPage
			this.getTableList()
		},
		// 提交搜索
		onSearch() {
			this.currentPage = 1;
			if (this.handleSearch) {
				this.$emit("onSearch")
			} else {
				this.getTableList()
			}
		},
		// 重置
		resetForm() {
			this.currentPage = 1
			this.pageSize = 20
			this.$emit('clearSearch')
			this.getTableList()
		},
		// 新增一行
		addItem() {
			if (this.addType === 'outTable') {
				this.$emit('addItemOutTable')
				this.$refs.xTable.insert(this.newRecord).then(({
					row
				}) => this.$refs.xTable.setActiveRow(row))
			} else if (this.addType === 'inTable') {
				this.$emit('addItemInTable')
			}
		},
		// 删除一行
		deleteItem() {
			let removeRecords = this.$refs.xTable.getCheckboxRecords()
			this.$refs.xTable.remove(removeRecords)
		},
		// 保存
		async saveTable() {
			const errMap = await this.$refs.xTable.validate().catch(errMap => errMap)
			if (!errMap) {
				this.$message('success')
				// 更新的数据
				console.log(this.$refs.xTable.getUpdateRecords())
				// 新增的数据
				console.log(this.$refs.xTable.getInsertRecords())
			}
		},
		// 打印
		printTable() {
			this.$refs.xTable.print({
				filename: this.tableName
			})
		},
		// 导出
		exportTable() {
			this.$refs.xTable.exportData({
				filename: this.tableName,
				modes: ["all"]
			})
		},
		// 导出自定义数据
		exportTableAll(data) {
			this.$refs.xTable.exportData({
				filename: this.tableName,
				data: data
			})
		},
		// 刷新
		refreshTable() {
			// this.currentPage = 1
			// this.pageSize = 10
			this.getTableList()
		},
		customSortMethod(e) { //排序
			this.$emit("sortColumn", e);
		},
		reload() {
			console.log(2222);
			this.tableStyle = `height: calc( 100% - ${this.$refs.toolbar.offsetHeight + this.$refs.pagination.offsetHeight + this.$refs.remark.offsetHeight}px );`
		}
	}
}
</script>

<style>
.toolbar .el-form-item {
	margin-bottom: 0 !important;
}

.vxe-required-icon {
	display: none !important;
}

.vxe-table--loading {
	background-color: hsla(0, 0%, 100%, 0.9);
}
</style>

<style lang="scss" scoped>
.tables {
	height: 100%;
	display: flex;
	flex-direction: column;
}

.title {
	margin-bottom: 10px;
	flex-shrink: 0;
}

.toolbar {
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
	position: relative;
	min-height: 40px;
	flex-shrink: 0;

	.info-box {
		font-size: 14px;
		display: flex;
		align-items: center;
		color: #666666;
		position: relative;
		z-index: 10;
		flex-grow: 1;
	}

	.search-box {
		position: relative;
		z-index: 10;
		flex-grow: 1;
	}

	.action-box {
		line-height: 40px;
		height: 40px;
		flex-shrink: 0;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		z-index: 9;
	}
}

.table {
	margin: 10px 0;
	flex-grow: 1;
}

.pagination {
	display: flex;
	justify-content: flex-end;
}

.remark {
	margin-top: 10px;
}
</style>

<style lang="scss" scoped>
// 小屏幕响应
@media screen and (max-width: 767px) {
	.toolbar {
		display: block;
	}

	.tables {
		height: auto;
		display: block;
	}
}
</style>
